<template>
  <!--    Expense Form-->
  <validation-observer
    ref="expenseForm"
    v-slot="{invalid}"
  >
    <lenon-modal
      :title="`${updateExpenseMode?'Update':'Create'} Expense`"
      :show="expenseModalOpened"
      :show-overlay="deletingExpense"
      size="md"
      @onClose="closeExpenseModal()"
    >
      <error-display :error="error" />
      <b-row class="align-items-start">
        <div class="col-7">
          <lenon-select
            v-model="selectedExpenseId"
            :options="expenses"
            label-name="title"
            value-name="id"
            placeholder="Expenses"
            @input="populateExpensesForm"
          />
        </div>
        <div class="col-2">
          <lenon-dropdown
            icon="TrashIcon"
          >
            <b-dropdown-item @click="deleteExpense()">
              Yes
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item>
              No
            </b-dropdown-item>
          </lenon-dropdown>
        </div>
        <div class="col-2">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            label=""
            tool-tip-text="Clear Form"
            @onClick="resetExpenseForm()"
          />
        </div>
      </b-row>
      <lenon-input
        v-model="expense.title"
        name="title"
        placeholder="Title, eg. Books purchase for teachers"
        rules="required"
        label="Title"
      />
      <lenon-text-area
        v-model="expense.details"
        name="details"
        placeholder="Expense Details, eg. Any relevant information you want to add"
        rules="required"
        label="Expense Details"
      />
      <lenon-input
        v-model="expense.amount"
        name="amount"
        placeholder="Enter Amount"
        rules="required|numeric"
        label="Amount"
      />
      <lenon-date-picker
        v-model="expense.date"
        name="date"
        label="Date"
        placeholder="Date"
        rules="required"
      />
      <template slot="modal-actions">
        <b-row class="float-right">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            @onClick="closeExpenseModal()"
          />
          <lenon-button
            :label="updateExpenseMode?'Update':'Create'"
            :disabled="invalid"
            :loading="expenseLoading"
            loading-text="Loading..."
            @onClick="updateExpenseMode?updateExpense():createExpense()"
          />
        </b-row>
      </template>
    </lenon-modal>
  </validation-observer>
</template>

<script>

import {
  BRow, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import confirm from '@/lenon/mixins/confirm'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import {
  CREATE_EXPENSE_M,
  DELETE_EXPENSE_M,
  UPDATE_EXPENSE_M,
} from '@/graphql/mutations'
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import LenonDatePicker from '@/lenon/components/LenonDatePicker.vue'
import logData from '@/libs/log'
import ErrorDisplay from '@/lenon/components/ErrorDisplay.vue'
import LenonTextArea from '@/lenon/components/LenonTextArea.vue'

export default {
  name: 'ExpenseSetup',
  components: {
    LenonTextArea,
    ErrorDisplay,
    LenonDatePicker,
    LenonDropdown,
    LenonSelect,
    LenonButton,
    LenonInput,
    LenonModal,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BDropdownDivider,
  },
  mixins: [showToast, confirm],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      error: {},
      deletingExpense: false,
      expenseModalOpened: false,
      updateExpenseMode: false,
      expenseLoading: false,
      selectedExpenseId: null,
      expense: {
        id: null,
        title: null,
        details: null,
        amount: null,
        date: null,
      },
    }
  },
  computed: {
    expenses() {
      return this.$store.getters['earnings/expenses']
    },
    selectedExpense() {
      return this
        .expenses.find(g => +g.id === +this.selectedExpenseId)
    },
  },
  watch: {
    modalOpened(opened) {
      this.expenseModalOpened = opened
    },
    selectedExpenseId(id) {
      if (!id) {
        this.resetExpenseForm()
      }
    },
    id(id) {
      if (id) {
        this.selectedExpenseId = id
      }
    },
  },
  methods: {
    resetExpenseForm() {
      this.updateExpenseMode = false
      this.expense = {
        id: null,
        title: null,
        details: null,
        amount: null,
        date: null,
      }
      this.selectedExpenseId = null
      this.$refs.expenseForm.reset()
    },
    populateExpensesForm(ef) {
      if (this.selectedExpense) {
        this.updateExpenseMode = true
        this.expense = {
          ...this.selectedExpense,
        }
        // eslint-disable-next-line no-underscore-dangle
        delete this.expense.__typename
      } else {
        this.resetExpenseForm()
      }
    },
    closeExpenseModal() {
      this.expenseModalOpened = false
      this.$emit('modalClosed')
    },
    updateExpense() {
      this.error = {}
      if (!this.expense.id) {
        return
      }
      this.expenseLoading = true
      this.$apollo.mutate({
        mutation: UPDATE_EXPENSE_M,
        variables: {
          input: {
            ...this.expense,
            amount: +this.expense.amount,
          },
        },
      })
        .then(res => {
          this.expenseLoading = false
          this.showSuccess('Updated expense successfully')
          this.$store.commit('earnings/updateExpense', res.data.updateExpense)
        })
        .catch(err => {
          this.error = err
          logData(err)
          this.showError('Failed to update expense')
          this.expenseLoading = false
        })
    },
    createExpense() {
      this.error = {}
      this.expenseLoading = true
      this.$apollo.mutate({
        mutation: CREATE_EXPENSE_M,
        variables: {
          input: {
            ...this.expense,
            amount: +this.expense.amount,
          },
        },
      })
        .then(res => {
          this.showSuccess('Created expense successfully')
          this.expenseLoading = false
          this.$store.commit('earnings/addExpense', res.data.createExpense)
          this.resetExpenseForm()
        })
        .catch(err => {
          this.error = err
          logData(err)
          this.showError('Failed to create expense')
          this.expenseLoading = false
        })
    },
    deleteExpense() {
      if (!this.selectedExpenseId) {
        this.showInfo('Please select a expense ')
        return
      }
      this.deletingExpense = true
      this.$apollo.mutate({
        mutation: DELETE_EXPENSE_M,
        variables: { id: this.selectedExpenseId },
      })
        .then(() => {
          this.deletingExpense = false
          this.showSuccess('Deleted expense successfully')
          this.$store.commit('earnings/removeExpense', this.selectedExpenseId)
          this.selectedExpenseId = null
          this.resetExpenseForm()
        })
        .catch(err => {
          logData(err)
          this.showError('Failed to delete expense')
          this.deletingExpense = false
        })
    },
  },
}
</script>
